import '../../css/Welcome.css';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// import { useDispatch, useSelector } from "react-redux";
import { Modal, message } from 'antd';
import Whatsapp from '../../components/Whatsapp';
import haleonLogo from '../../images/Haleon/HaleonLogo.svg';
import img1 from '../../images/Haleon/happyFamily.png';
import rejected from '../../images/Haleon/rejected.png';
import axiosClient from '../../utils/axiosClient';
import InvalidQrCode from '../../components/Modal/InvalidQrCode';
import HaleonLogo from '../../images/Haleon/Frame.svg';

const isSGRegion =
	window.location.hostname.replace('www.', '') ===
	process.env.REACT_APP_SG_DOMAIN;

const Welcome = ({ t }) => {
	const searchParams = new URLSearchParams(window.location.search);
	const navigate = useNavigate();
	const location = useLocation();
	// const { error } = useSelector((state) => state.user);
	// const [searchParams] = useSearchParams();

	const referCode = searchParams.get('referCode');
	const channel = searchParams.get('channel');
	const storeCode = searchParams.get('storeCode');
	const promoterCode = searchParams.get('promoterCode');
	const [errorModal, setErrorModal] = useState(false);
	const language = localStorage.getItem('HALEON-REWARDS_LANG');

	// useEffect(() => {
	// 	if (channel) {
	// 		validateChannelOrThrowError(channel);
	// 	} else if (promoterCode) {
	// 		validatePromoterCodeOrThrowError(promoterCode);
	// 	} else if (referCode) {
	// 		validateReferralCodeOrThrowError(referCode);
	// 	} else if (storeCode) {
	// 		validateStoreCodeOrThrowError(storeCode);
	// 	}
	// }, [channel, promoterCode, referCode, storeCode]);

	useEffect(() => {
		if (channel) {
			validateChannelOrThrowError(channel);
			window.location.href = `https://api.whatsapp.com/send?phone=60167015382&text=%5B${channel}%5D%20Hi%2C%20I%20would%20like%20to%20join%0AHaleon%20Rewards!`;
		}
		if (!isSGRegion) {
			   if (promoterCode) {
				validatePromoterCodeOrThrowError(promoterCode);
				window.location.href = `https://api.whatsapp.com/send?phone=60167015382&text=%5B${promoterCode}%5D%20Hi%2C%20I%20would%20like%20to%20join%0AHaleon%20Rewards!`;
			} else if (referCode) {
				validateReferralCodeOrThrowError(referCode);
				window.location.href = `https://api.whatsapp.com/send?phone=60167015382&text=%5B${referCode}%5D%20Hi%2C%20I%20would%20like%20to%20join%0AHaleon%20Rewards!`;
			} else if (storeCode) {
				validateStoreCodeOrThrowError(storeCode);
				window.location.href = `https://api.whatsapp.com/send?phone=60167015382&text=%5B${storeCode}%5D%20Hi%2C%20I%20would%20like%20to%20join%0AHaleon%20Rewards!`;
			}
		}
	}, [channel, promoterCode, referCode, storeCode]);

	async function validatePromoterCodeOrThrowError(_promoterCode) {
		if (!_promoterCode) return;

		try {
			const res = await axiosClient.get(
				`/public/promoters?promoterCode=${_promoterCode}`
			);
			const _resPromoter = res?.data?.[0];
			const _resPromoterCode = _resPromoter && _resPromoter?.promoterCode;
			if (_resPromoterCode === _promoterCode && _resPromoter?.id) {
				message.success('Promoter code is valid!');
				localStorage.setItem('HALEON-REWARDS_PROMOTER_ID', _resPromoter?.id);
				localStorage.setItem('HALEON-REWARDS_PROMOTER_CODE', _promoterCode);
			} else {
				setErrorModal(true);
				message.error('Promoter code is invalid!');
			}
		} catch (err) {
			console.error(err);
		}
	}

	async function validateReferralCodeOrThrowError(_referralCode) {
		if (!_referralCode) return;

		try {
			const res = await axiosClient.get(
				`/public/referrer?referralCode=${_referralCode}`
			);
			const _resReferralCode = res?.data?.referralCode;
			if (_resReferralCode === _referralCode) {
				localStorage.setItem('HALEON-REWARDS_REFER_CODE', _referralCode);
				message.success('Referral code is valid!');
			} else {
				setErrorModal(true);
				message.error('Referral code is invalid!');
			}
		} catch (err) {
			console.error(err);
		}
	}

	async function validateChannelOrThrowError(_channel) {
		if (!_channel) return;

		try {
			const res = await axiosClient.get(
				`/public/campaigns?channel=${_channel}`
			);
			const _resChannels = res?.data?.config?.channels || [];
			if (_resChannels.includes(_channel.toUpperCase())) {
				localStorage.setItem('HALEON-REWARDS_CHANNEL', _channel.toUpperCase());
				message.success('Channel is valid!');
			} else {
				message.error('Channel is invalid!');
				setErrorModal(true);
			}
		} catch (err) {
			console.error(err);
		}
	}

	async function validateStoreCodeOrThrowError(_storeCode) {
		if (!_storeCode) return;

		try {
			const res = await axiosClient.get(
				`/public/retailers?storeCode=${_storeCode}`
			);
			if (res.data.length > 0 && res.data[0]?.storeCode === _storeCode) {
				message.success('Store code is valid!');

				localStorage.setItem('HALEON-REWARDS_STORE_ID', res.data[0]?.id);
				localStorage.setItem('HALEON-REWARDS_STORE_CODE', _storeCode);
			} else {
				setErrorModal(true);
				// message.error('Store code is invalid!');
			}
		} catch (err) {
			console.error(err);
		}
	}

	const handleNavigate = () => {
		navigate('/getStarted' + window.location.search);
	};

	return (
		<div>
			<div>
				<div className="logo-position">
					<img src={haleonLogo} alt="haleon" style={{ width: '30%' }} />
				</div>
				<img src={img1} alt="pic" className="w-100" />
				<div className="layout-position">
					<div className="getStarted-card">
						<div className="ml-3 mr-3">
							<div style={{ textAlign: 'left' }}>
								<br />
								<p
									style={{ fontSize: '25px', lineHeight: '28px' }}
									className="font-weight-400"
								>
									{t('Welcome To')}
								</p>
								<p
									style={{ fontSize: '25px', lineHeight: '28px' }}
									className="font-weight-700"
								>
									<b style={{ color: '#30E700' }}>Haleon Rewards!</b>
								</p>
								{language === 'en' ? (
									<p className="mt-2 fontSize-16 font-weight-400">
										Earn points by uploading your Haleon
										<br />
										purchase receipts and unlock a world of <br />
										exclusive benefits and exciting rewards.
									</p>
								) : (
									<p className="mt-2 fontSize-16 font-weight-400">
										{t(
											'Earn points by uploading your Haleon purchase receipts and unlock a world of exclusive benefits and exciting rewards.'
										)}
									</p>
								)}
							</div>
							<div className="mt-5">
								<button className="getStartedBtn font-family-hero-new" onClick={handleNavigate}>
									{t('Get Started')}
								</button>
							</div>
						</div>
					</div>
				</div>
				<InvalidQrCode setErrorModal={setErrorModal} errorModal={errorModal} />
			</div>
		</div>
	);
};

export default Welcome;
